import { outYunStore } from '@/service/cloudWarehouse/index';

export default {
    name: '',
    data() {
        return {
            address:{},
            order: {}
        }
    },
    mounted(){
        if(this.$route.query.address){
            this.address = JSON.parse(decodeURI(this.$route.query.address))
        }

        let oList = JSON.parse(decodeURI(this.$route.query.order))
        oList.total = 0
        oList.productList.forEach(item =>{
            oList.total += item.sum
        })
        this.order = oList
    },
    methods:{
        // 点击地址
        onAddress(){
            this.$router.push({
                name: 'address-list',
                query:{
                    from: 'cloudAddress',
                    order: this.$route.query.order
                }
            })
        },

        // 确定
        async onConfirm(){
            let { order, address } = this;
            if(Object.keys(address).length == 0) return this.$toast('请选择地址')

            let plist = order.productList.map(item =>{
                return{
                    formatId: item.formatId,
                    productId: item.productID,
                    outStock: item.sum
                }
            })
            let params = {
                code: order.code,
                productList: plist,
                address: {
                    name: address.name,
                    phone: address.phone,
                    regionId: address.regionId,
                    detail: address.detail,
                    fullAddress: this.$getFullAddress(address.region) + address.detail
                }
            }
            const res = await outYunStore(params)
            this.$router.replace({
                path: '/cloudWarehouse/success'
            })
        }
    },
}  